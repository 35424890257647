
<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div>
      <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品分类.分类名称')" prop="categoryName">
                    <a-input v-model="queryParam.categoryName" @pressEnter="handleQuery" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品分类.分类名称')" allow-clear/>
                  </a-form-item>
                </a-col>
<!--              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('报表.商品.订单开始时间')" prop="startTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.startTime"
                                 :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                                 valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('报表.商品.订单结束时间')" prop="endTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.endTime"
                                 :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                                 valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>-->
              <a-col :md="!advanced && 6 || 24" :sm="24">
                <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
          <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
            <img alt="example" style="width: 100%" :src="previewUrl" />
          </a-modal>
        </div>
      <!-- 操作 -->
        <div class="table-operations">
          <a-button type="primary" @click="handleExport" v-hasPermi="['goods:goods:export']">
            <a-icon type="download" />{{$t('通用.按钮.导出')}}
          </a-button>
          <a-button type="primary" :disabled="multiple"  @click="handleSelectExport" v-hasPermi="['goods:goods:export']">
            <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
          </a-button>
          <table-setting
            :style="{float: 'right'}"
            :table-size.sync="tableSize"
            v-model="columns"
            :refresh-loading="loading"
            @refresh="getList" />
        </div>
        <!-- 数据展示 -->
        <a-table
          class="sta-table"
          :loading="loading"
          size="small"
          rowKey="categoryId"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="handleSortChange"
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :bordered="tableBordered"
        >

          <template slot="picture" slot-scope="text, record">
            <div>
              <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
            </div>
          </template>
          <span slot="payAmount" slot-scope="text, record">
          ¥{{ record.payAmount }}
        </span>
          <span slot="goodsAmount" slot-scope="text, record">
          ¥{{ record.goodsAmount }}
        </span>
          <span slot="refundAmount" slot-scope="text, record">
          ¥{{ record.refundAmount }}
        </span>
          <span slot="orderAmount" slot-scope="text, record">
          ¥{{ record.orderAmount }}
        </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :pageSizeOptions="pageSizeOptions"
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="total => getTotal(total)"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"
        />
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {cateSta} from '@/api/statistics/goods'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import {tableMixin} from '@/store/table-mixin'
import moment from 'moment';

export default {
  name: 'Goods',
  components: {
    CustomDictTag,
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      categoryList: [{id: "0", categoryName: ''}],
      cateOptions: [],
      brandList: [],
      labelList: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        categoryName: undefined,
        startTime: undefined,
        endTime: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('商品分类.分类名称'),
          dataIndex: 'categoryName',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: this.$t('报表.商品.商品销量'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'salesNum',
        },
        {
          title: this.$t('报表.商品.商品销售额'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'goodsAmount',
          scopedSlots: {customRender: 'goodsAmount'},

        },
        {
          title: this.$t('报表.商品.销售金额'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'payAmount',
          scopedSlots: {customRender: 'payAmount'},

        },
        {
          title: this.$t('报表.商品.销售占比'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'payAmountRate',
        },
        {
          title: this.$t('报表.商品.退款金额'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'refundAmount',
          scopedSlots: {customRender: 'refundAmount'},

        },
        {
          title: this.$t('报表.商品.退款单数'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'refundOrderCount',
        },
        {
          title: this.$t('报表.商品.退款占比'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'refundAmountRate',
        },
        {
          title: this.$t('报表.商品.退款量'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'refundNum',
        },
      ]
    };
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted() {
    this.resetQuery();
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询商品列表 */
    getList () {
      this.loading = true
      cateSta(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },



    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        categoryName: undefined,
        startTime: undefined,
        endTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.categoryId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },


    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('statistics/goods/cate/export',
            that.queryParam
          , `商品分类报表_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('statistics/goods/cate/export', queryParam, `商品分类报表_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        }
      })
    }
  }
}
</script>
<style>
.sta-table .ant-table-thead > tr > th {
  background-color: #bae7ff !important;
}

</style>
